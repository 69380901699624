import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import "./refreshpage.css";

const RefreshPage =()=>
{
    const navigate = useNavigate();

    const  [loading,setLoading] = useState(false);
    useEffect(
        ()=>{
            setLoading(true)
           
            setTimeout(()=>{
                setLoading(false)
                navigate('/abc/');

            },5000)
        },[]
    )
    return(
        loading ?
        <div>
            <center className="stylingtologo">
            
             
             <RingLoader
                    color={"#0E5C95"}
                      loading={loading}
                     size={150}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    
                    
             />
             <h1>Operation Taking Place!</h1>
            </center>
            
                 
        </div>
       
       
        :

        <div>
                
        </div>
    )
}
export default RefreshPage;