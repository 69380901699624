import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import "./refreshpage.css";

const RefreshRandom =()=>
{
    const navigate = useNavigate();

    const  [loading,setLoading] = useState(false);
    const  [load,setLoadingNext] = useState(false);
    const  [list,setLoadingNav] = useState(false);
    useEffect(
        ()=>{
            setLoading(true)
           
            setTimeout(()=>{
                setLoading(false)
                navigate('/abc/');

            },4000)
        },[]
    )
    useEffect(
        ()=>{
            setLoadingNext(true)
           
            setTimeout(()=>{
                setLoadingNext(false)
            },2000)
        },[])
        
    useEffect(
        ()=>{
            setLoadingNav(true)
           
            setTimeout(()=>{
                setLoadingNav(false)
                
            },2000)
        },[]
    )
    return(
        loading ?
        <div>
            <center className="stylingtologo">
            
             
             <HashLoader

                    color={"#0E5C95"}
                      loading={loading}
                     size={90}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    
                    
             />
             
             
             <h1 style={{marginTop:"10%"}}>Doing Further Processes Please Be Patient...</h1>
            
            </center>
            
                 
        </div>
       
       
        :

        <div>
        
     
        </div>
        

    )
}
export default RefreshRandom;