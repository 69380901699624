import React, { useState } from "react";
import "./patient_entry.css"
import { initializeApp } from "firebase/app";
import { addDoc, and, collection, DocumentSnapshot, getDocs, getFirestore,onSnapshot } from "firebase/firestore";
import { doc, setDoc,updateDoc,getDoc } from "firebase/firestore";
import { auth,obj,type1,type2,type3,type4,type5,type6,type7,type8,type9,type10,type11,type12,type13,type14,type16,type17,type18,type19,type20,type21,type22,type23,type24,type25,type26,type27,type28,type29,type30,type31, type32} from "./fbgen2" 
import { useNavigate } from "react-router-dom";
import Navbar from "./NavBar";

const PT = "PT";

function Patient_Entry()
{ 
  const navigate = useNavigate();
 /* const [fname,setFName] = useState('');
  const [mname,setMName] = useState('');
  const [lname,setLName] = useState('');
  const [BMI,setBMI] = useState('');
  const [FHCAD,setFHCAD] = useState('');
  */

  const[name,setname] = useState('');
  const [age,setAge] = useState('');
  
  const [DM,setDM] = useState('');
  const [HTN,setHTN] = useState('');
  const [smoking,setSmoking] = useState('');
  const [CKD,setCKD] = useState('');
  const [CVA,setCVA] = useState('');
  const [prevCABGorPCI,setprevCABGorPCI] = useState('');
  const [comorbidity,setCormorbidty] = useState('');
  const [CSAorACS,setCSAorACS] = useState('');
  const [EF,setEF] = useState('');
  const [date,setDate] = useState('');
  const [Sex,setSex] = useState('');
  const [Dyslipidemia,setDyslipidemia] = useState('');
  const [PAD,setPAD] = useState('');
  const [PCI,setPCI] = useState('');
  const [CABG,setCABG] = useState('');
  const [diagnosis,setDiagnosis] = useState('');
  const [TACS,setTACS] = useState('')
  const [OperatorName,setOperatorName] = useState('')
  const [TreatmentArm,setTreatmentArm] = useState('')
  const insert = (e) =>{
    e.preventDefault();
    const colref = collection(obj,"SNO")
    if (name === "" || OperatorName === "" || date == "") {
      alert ("Please Fill the Patient name, Operator Name and the Date of Procedure")
    }
    else {
      //const snref = doc(obj,"SNO")
      let ent = []
      let key = ""
      let PT= ""
      let array_index = 0;
      
      const setData = {
          Name:name,
          Age: age,
          DM: DM,
          HTN: HTN,
          Smoking: smoking,
          CKD: CKD,
          CVA: CVA,
          PrevCABGorPCI: prevCABGorPCI,
          Comorbidity: comorbidity,
          CSAorACS: CSAorACS,
          EF: EF,
          Date: date,
          Sex: Sex,
          Dyslipidemia: Dyslipidemia,
          PAD: PAD,
          PCI: PCI,
          CABG: CABG,
          Diagnosis: diagnosis,
          TACS: TACS,
          OperatorName: OperatorName,
          TreatmentArm:TreatmentArm,
          LM: "",
          LAD: "",
          LCx: "",
          RCA: "",
          SVDorDVDorTVD: "",
          SyntaxScore:"",
          IVUSorOCT:"",
          MB:"",
          Route:"",
          Guide: "",
          Plaque:"",
          MLA:"",
          MBDRD:"",
          SBDRD:"",
          SBOstil:"",
          LMDiameter:"",
          PlaqueMDevice:"",
          MBBalloon:"",
          MBBalloonPressure:"",
          SBdilatation:"",
          StentName:"",
          StenSize:"",
          POTBalloonSize:"",
          POTBalloonPressure:"",
          MBPostDilatation:"",
          AngiographicSBC:"",
          ChestPain:"",
          ECGChanges:"",
          TIMIFlow: "",
          SBFFR:"",
          KBI:"",
          MBBalloonSizeForKBI:"",
          SBBalloonSizeForKBI:"",
          SBComplicationAfterKBI:"",
          MBMSA:"",
          LMMSA:"",
          dLM:"",
          ostialLAD:"",
          MBexpansion:"",
          LMexpansion:"",
          EdgeDissection: "",
          WellApposed:"",
          PlaqueProlapse:"",
          NStentStruts:"",
          LinkType:"",
          RNStentStruts:"",
          SBODissection:"",
          // FinalSBO:"",
          DocumentCaudalViews:"",
          CAGSB:"",
          CAGMB:"",
          IVUSorOCTafter:"",
          Epithelialization:"",
          cardiacDeath:"",
          Myocardial:"",
          TargetLesion:"",
          EventDate:"",
          StentThrombosis:"",
          ModifiedDate: "",
          ModifiedBy: ""
      }
      
      getDocs(colref)
          .then((snapshot) =>{
            let key1 = 0
              snapshot.docs.forEach((doc)=>{
                  ent.push({ ...doc.data(), id: doc.id})
              })
              let data=''
              let Value=''
            
              if(auth.currentUser.email===type1)
              {
                data = "CET02";
                PT= "AHRR";
                array_index = 1; 
              }
              else if(auth.currentUser.email===type2)
              {
                data = "CET03";
                PT= "CHAFB";
                array_index = 10; 
              }
              else if(auth.currentUser.email===type3)
              {
                data = "CET04";
                PT= "PGI";
                array_index = 21; 
              }
              else if(auth.currentUser.email===type4)
              {
                data = "CET05";
                PT= "SGPGI";
                array_index = 24; 
              }
              else if(auth.currentUser.email===type5)
              {
                data = "CET06";
                PT= "JH";
                array_index = 14; 
              }
              else if(auth.currentUser.email===type6)
              {
                data = "CET07";
                PT= "KIMS";
                array_index = 16; 
              }
              else if(auth.currentUser.email===type7)
              {
                data = "CET08";
                PT= "SGMM";
                array_index = 23; 
              }
              else if(auth.currentUser.email===type8)
              {
                data = "CET09";
                PT= "MSC";
                array_index = 20; 
              }
              else if(auth.currentUser.email===type9)
              {
                data = "CET10";
                PT= "SGRRIMHS";
                array_index = 25; 
              }
              else if(auth.currentUser.email===type10)
              {
                data = "CET11";
                PT= "RH";
                array_index = 22; 
              }
              else if(auth.currentUser.email===type11)
              {
                data = "CET12";
                PT= "JLNA";
                array_index = 15; 
              }
              else if(auth.currentUser.email===type12)
              {
                data = "CET13";
                PT= "LFHRC";
                array_index = 17; 
              }
              else if(auth.currentUser.email===type13)
              {
                data = "CET14";
                PT= "YHS";
                array_index = 30; 
              }
              else if(auth.currentUser.email===type14)
              {
                data = "CET15";
                PT= "AG";
                array_index = 0; 
              }
              else if(auth.currentUser.email===type16)
              {
                data = "CET16";
                PT = "GRH";
                array_index = 12;
              }
              else if(auth.currentUser.email===type17)
              {
                data = "CET17";
                PT = "MHCCH";
                array_index = 19;
              }
              else if(auth.currentUser.email===type18)
              {
                data = "CET18";
                PT = "MD";
                array_index = 18;
              }
              else if(auth.currentUser.email===type19)
              {
                data = "CET19";
                PT = "IH";
                array_index = 13;
              }
              else if(auth.currentUser.email===type20)
              {}
              else if(auth.currentUser.email===type21)
              {
                data = "CET21";
                PT = "SRMK";
                array_index = 27;
              }
              else if(auth.currentUser.email===type22)
              {
                data = "CET22";
                PT = "SSH";
                array_index = 28;
              }
              else if(auth.currentUser.email===type23)
              {
                data = "CET23";
                PT = "UH";
                array_index = 29;
              }
              else if(auth.currentUser.email===type24)
              {
                data = "CET24";
                PT = "CET24";
                array_index = 3;
              }
              else if(auth.currentUser.email===type25)
              {
                data = "CET25";
                PT = "CET25";
                array_index = 4;
              }
              else if(auth.currentUser.email===type26)
              {
                data = "CET26";
                PT = "CET26";
                array_index = 5;
              }
              else if(auth.currentUser.email===type27)
              {
                data = "CET27";
                PT = "CET27";
                array_index = 6;
              }
              else if(auth.currentUser.email===type28)
              {
                data = "CET28";
                PT = "CET28";
                array_index = 7;
              }
              else if(auth.currentUser.email===type29)
              {
                data = "CET29";
                PT = "CET29";
                array_index = 8;
              }
              else if(auth.currentUser.email===type30)
              {
                data = "CET30";
                PT = "CET30";
                array_index = 9;
              }
              else if(auth.currentUser.email===type31)
              {
                data = "CET31";
                PT = "SH";
                array_index = 26;
              }
              else if(auth.currentUser.email===type32)
              {
                data = "CET32";
                PT = "CHMH";
                array_index = 11;
              }
              else{
                data = "CET01";
                PT= "AICTS";
                array_index = 2; 
              }

              //console.log(ent)
              //console.log(PT)
              key1 =ent[array_index].VALUE;
              key1 = key1 + 1;
              //console.log(key1)
              if(key1>=0 && key1<=9)
              {
                key = PT + "00" + String(key1)
              }
              else if(key1>=10 && key1<=99)
              {
                key = PT + "0" + String(key1)
              }
              else
              {
                key = PT + String(key1)
              }
              const docref = doc(obj,data,key)
              const docref1 = doc(obj,"SNO",PT)
              const setData1 ={
                VALUE: key1
              }
                setDoc(docref,setData)
                .then(()=>{
                  alert("SUCCESSFULLY INSERTED.");
                  navigate(`/random/${key}`);
                    //console.log("Insert Successful");
                    //console.log(key1)
                })
                .catch(error => {
                  console.log(error);
                  alert("ERROR OCCURED");
                })
                
                setDoc(docref1,setData1)
                .then(()=>{
                      //console.log("Insert1 Successful");
                    
                })
                .catch(error => {
                  console.log(error);
                })
      })
    }
  }

    return (
      
      <div>
               <div id="nav-z"><Navbar/></div>
          

            <div id="patient-page">
            <div id="form">
              <div id="login">
                <div id="login-header">
                  <h3>Patient Entry</h3>
                  <p>Please enter Patient credentials</p>
                </div>
              </div>
              
              <form>
                <input type = "text" name="name" value={name} id="id1" placeholder="Name" onChange={(e)=>{setname(e.target.value)}} required/>
                {/*<input type="text" name="treatmentarm" value={TreatmentArm} placeholder="Treatment Arm" onChange={(e) =>{setTreatmentArm(e.target.value)}}/>*/}
                <input type="text" name="operatorname" value={OperatorName} placeholder="Operator Name" onChange={(e) =>{setOperatorName(e.target.value)}} required/>
                Date Of Procedure:
                <input type="date" value={date} name="date"  size="30" onChange={(e) =>{setDate(e.target.value)}} required/> 
                <input type="number" name="age" value={age} id="id1" placeholder="Age" onChange={(e) =>{setAge(e.target.value)}}/>
               
                Sex: <br/>
                <select name="Sex" value={Sex} onChange={(e) =>{setSex(e.target.value)}}>
                  <option defaultValue={""}></option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                </select>

                
               {/* <input type="text" name="DM" value={DM} placeholder="DM" onChange={(e) =>{setDM(e.target.value)}}/>*/}
                <input type="text" name="HTN" value={HTN} placeholder="HTN (Y/N)" onChange={(e) =>{setHTN(e.target.value)}}/>
                <input type="text" name="Dyslipidemia" value={Dyslipidemia} placeholder="Dyslipidemia (Y/N)" onChange={(e) =>{setDyslipidemia(e.target.value)}}/>
                
                <input type="text" name="smoking" value={smoking} placeholder="Smoking? (Y/N)" onChange={(e) =>{setSmoking(e.target.value)}}/>
                <input type="text" name="CKD" value={CKD} placeholder="CKD (Y/N)" onChange={(e) =>{setCKD(e.target.value)}}/>
                <input type="text" name="PAD" value={PAD} placeholder="PAD (Y/N)" onChange={(e) => {setPAD(e.target.value)}}/>
                <input type="text" name="PCI" value={PCI} placeholder="Prior PCI (Y/N)" onChange={(e) => {setPCI(e.target.value)}}/>
                <input type="text" name="CABG" value={CABG} placeholder="Prior CABG (Y/N)" onChange={(e) =>{setCABG(e.target.value)}}/>
                <input type="text" name="comorbidity" value={comorbidity} placeholder="Any other Co-Morbidity" onChange={(e) =>{setCormorbidty(e.target.value)}}/>
                <input type="text" name="Diagnosis" value={diagnosis} placeholder="Diagnosis" onChange={(e)=>{setDiagnosis(e.target.value)}}/>

                <input type="text" name="CSAorACS" value={CSAorACS} placeholder="CSA/ACS" onChange={(e) =>{setCSAorACS(e.target.value)}}/>
                <input type="text" name="TACS" value={TACS} placeholder="Type of ACS (STEMI/NSTEMI/USA)" onChange={(e)=>{setTACS(e.target.value)}}/>

                <input type="number" name="EF" value={EF} placeholder="EF%" onChange={(e) =>{setEF(e.target.value)}}/>
                
                <br></br>
                <br/>
                </form>
                <button onClick={insert}><b>Insert</b></button>
            </div>
           
              </div>  
      </div>
          
    )
}
export default Patient_Entry
