import React, { useState } from "react";
import "./patient_entry.css"
import { initializeApp } from "firebase/app";
import { addDoc, collection, DocumentSnapshot, getDocs, getFirestore,onSnapshot } from "firebase/firestore";
import { doc, setDoc,updateDoc,getDoc } from "firebase/firestore";
import { obj } from "./fbgen2" 

const PT = "PT";

function PT2()
{
  const [fname,setFName] = useState('');
  const [mname,setMName] = useState('');
  const [lname,setLName] = useState('');
  const [age,setAge] = useState('');
  const [BMI,setBMI] = useState('');
  const [DM,setDM] = useState('');
  const [HTN,setHTN] = useState('');
  const [smoking,setSmoking] = useState('')
  const [FHCAD,setFHCAD] = useState('')
  const [CKD,setCKD] = useState('')
  const [CVA,setCVA] = useState('')
  const [prevCABGorPCI,setprevCABGorPCI] = useState('')
  const [comorbidity,setCormorbidty] = useState('')
  const [CSAorACS,setCSAorACS] = useState('')
  const [EF,setEF] = useState('')
    

  
    return (
          <div id="patient-page">
            <div id="form">
              <div id="login">
                <div id="login-header">
                  <h3>Patient Entry</h3>
                  <p>Please enter Patient credentials</p>
                </div>
              </div>
              
              <form>
                <input type="text" name="fname" value={fname} placeholder="First Name" onChange={(e) =>{setFName(e.target.value)}} />
                <input type="text" name="mname" value={mname} placeholder="Middle Name" onChange={(e) =>{setMName(e.target.value)}}/>
                <input type="text" name="lname" value={lname} placeholder="Last Name" onChange={(e) =>{setLName(e.target.value)}}/>
                <input type="number" name="age" placeholder="Age"/>
               
                <input type="number" placeholder="BMI"/>
                <input type="text" placeholder="DM"/>
                <input type="text" placeholder="HTN"/>
                <input type="text" placeholder="Smoking?"/>
                <input type="text" placeholder="FHCAD"/>
                <input type="text" placeholder="CKD"/>
                <input type="text" placeholder="CVA"/>
                <input type="text" placeholder="Previous CABG/PCI"/>
                <input type="text" placeholder="Co-Morbidity"/>
                <input type="text" placeholder="CSA/ACS"/>
                <input type="number" placeholder="EF"/>
                DOB:
                <input type="date" name="dob" id="dob" size="30"/> 
                
                Sex: <br/>
                <select>
                    <option value></option>
                    <option value>Male</option>
                    <option value>Female</option>
                    <option value>Other</option>
                </select>
                
                <br/>
                <p>Diagnosis:<textarea placeholder="Diagnosis:" rows="5" cols="33"></textarea></p>
               
                </form>
                <button><b>Insert</b></button>
            </div>
           
          </div>  
    )
}
export default PT2