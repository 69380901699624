import React from "react";
import "./Procedure.css"
import Navbar from "./NavBar";

function Procedure()
{ 
    return (
        <div>   
            <Navbar/>
            <div class="procimage-container">
            <img className="procimage" src={require('./images/slide6.jpeg')} />
                <img className="procimage" src={require('./images/slide5.jpeg')} />
                
            </div>
        </div>
          
    )
}
export default Procedure;