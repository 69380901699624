import React, { useState } from "react";
import "./patient_info.css"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { doc,setDoc,updateDoc,collection,getDocs,getDoc } from "firebase/firestore";
import { auth, obj } from "./fbgen2" 
import RefreshPage from "./RefreshPage";
import { useEffect } from "react";
const Patient_Info = () => {
  
  
 
  const [fname,setFName] = useState('');
  const [mname,setMName] = useState('');
  const [lname,setLName] = useState('');
  
  const[name,setname] = useState('');
  const [TreatmentArm,setTreatmentArm] = useState('')
  const [Sex,setSex] = useState(undefined);
  const [Dyslipidemia,setDyslipidemia] = useState('');
  const [PAD,setPAD] = useState('');
  const [PCI,setPCI] = useState('');
  const [CABG,setCABG] = useState('');
  const [TACS,setTACS] = useState('')



  const [age,setAge] = useState('');
  
  const [DM,setDM] = useState('');
  const [HTN,setHTN] = useState('');
  const [smoking,setSmoking] = useState('');
  const [FHCAD,setFHCAD] = useState('');
  const [CKD,setCKD] = useState('');
  const [CVA,setCVA] = useState('');
  const [prevCABGorPCI,setprevCABGorPCI] = useState('');
  const [comorbidity,setCormorbidty] = useState('');
  const [CSAorACS,setCSAorACS] = useState('');
  const [EF,setEF] = useState('');
  const [date,setDate] = useState('');
  const [OperatorName,setOperatorName] = useState('')
  const [Gender,setGender] = useState("");
  const [diagnosis,setDiagnosis] = useState('');
  const [lm,setLm] = useState('');
  const [lad,setLad] = useState('');
  const [lcx,setLcx] = useState('');
  const [rca,setRca] = useState('');
  const[medina,setmedina] = useState('');
  const [svdordvdortvd,setSvd] = useState('');
  const [intervention,setInterv] = useState('');
  const [SyntaxScore,setSyntaxScore] = useState('');
  const[IVUSorOCT,steIVUSorOCT] = useState('');
  const[MB,setMB] = useState('');
  const[Route,setRoute] = useState('');
  const [guide,setGuide] = useState('');
  const[plaque,setplaque] = useState('');
  const[MLA,setMLA] = useState('');
  const[MBDRD,setMBDRD] = useState('');
  const[SBDRD,setSBDRD] = useState('');
  const[SBOstil,setSBOstil] = useState('');
  const[LMDiameter,setLMDiameter]=useState('');
  const[PlaqueMDevice,setPlaqueMDevice] = useState('');
  const[MBBalloon,setMBBalloon] = useState('');
  const[MBBalloonPressure,setMBBalloonPressure] = useState('');
  const[SBdilatation,setSBdilatation] = useState('');
  const[StentName,setStentName] = useState('');
  const [stensize,setStsize] = useState('');
  const[POTBalloonSize,setPOTBalloonSize] = useState('');
  const[POTBalloonPressure,setPOTBalloonPressure] = useState('');
  const[MBPostDilatation,setMBPostDilatation] = useState('');
  const[AngiographicSBC,setAngiographicSBC] = useState('');
  const[ChestPain,setChestPain] = useState('');
  const[ECGChanges,setECGChanges] = useState('');
  const [timiflow,setTFlow] = useState('');
  const[SBFFR,setSBFFR] = useState('');
  const[KBI,setKBI] = useState('');
  const[MBBalloonSizeForKBI,setMBBalloonSizeForKBI] = useState('');
  const[SBBalloonSizeForKBI,setSBBalloonSizeForKBI] = useState('');
  const[SBComplicationAfterKBI,setSBComplicationAfterKBI] = useState('');
  const[MBMSA,setMBMSA] = useState('');
  const[LMMSA,setLMMSA] = useState('');
  const[dLM,setdLM] = useState('');
  const[ostialLAD,setostialLAD] = useState('');
  const[MBexpansion,setMBexpansion] = useState('');
  const[LMexpansion,setLMexpansion] = useState('');
  const [edgedissection,setEdissection] = useState('');
  const[WellApposed,setWellApposed] = useState('');
  const[PlaqueProlapse,setPlaqueProlapse] = useState('');
  const[NStentStruts,setNStentStruts] = useState('');
  const[LinkType,setLinkType] = useState('');
  const[RNStentStruts,setRNStentStruts] = useState('');
  const[SBODissection,setSBODissection] = useState('');
  const[FinalSBO,setFinalSBO] = useState('');
  const[DocumentCaudalViews,setDocumentCaudalViews]= useState('');
  const[CAGSB,setCAGSB] = useState('');
  const[CAGMB,setCAGMB] = useState('');
  const[IVUSorOCTafter,setIVUSorOCTafter] = useState('');
  const[Epithelialization,setEpithelialization] = useState('');
  const[cardiacDeath,setcardiacDeath] = useState('');
  const[Myocardial,setMyocardial] = useState('');
  const[TargetLesion,setTargetLesion] = useState('');
  const[StentThrombosis,setStentThrombosis] = useState('');
  const[EventDate,setEventDate] = useState('');
  const {id} = useParams();
  const {db} = useParams();
  const navigate = useNavigate();
  const [ID,setID] = useState(id);

  
  const onEdit = (e) =>{
    e.preventDefault();
     const r = doc(obj,db,id);
    let flag = 0;
     if(name!=="")
     {
      const sr = { Name: name} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(age!=="")
     {
      const sr = { Age: age} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(Sex!==undefined)
     {
      const sr = { Sex: Sex} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(DM!=="")
     {
      const sr = { DM: DM} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(HTN!=="")
     {
      const sr = { HTN: HTN} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(Dyslipidemia!=="")
     {
      const sr = { Dyslipidemia: Dyslipidemia} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(smoking!=="")
     {
      const sr = { Smoking: smoking} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(CKD!=="")
     {
      const sr = { CKD: CKD} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(PAD!=="")
     {
      const sr = { PAD: PAD} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(PCI!=="")
     {
      const sr = { PCI: PCI} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(CABG!=="")
     {
      const sr = { CABG: CABG} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(comorbidity!=="")
     {
      const sr = { Comorbidity: comorbidity} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(diagnosis!=="")
     {
      const sr = { Diagnosis: diagnosis} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     
     if(CSAorACS!=="")
     {
      const sr = { CSAorACS: CSAorACS} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(TACS!=="")
     {
      const sr = { TACS: TACS} 
      setDoc(r,sr,{merge : true});
      flag=1
     }


     if(EF!=="")
     {
      const sr = { EF: EF} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(date!=="")
     {
      const sr = { Date: date} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(OperatorName!=="")
     {
      const sr = { OperatorName: OperatorName} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(lm!=="")
     {
      const sr = { LM: lm} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(lad!=="")
     {
      const sr = { LAD: lad} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(lcx!=="")
     {
      const sr = { LCx: lcx} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(rca!=="")
     {
      const sr = { RCA: rca} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(medina!=="")
     {
      const sr = { medina: medina} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(svdordvdortvd!=="")
     {
      const sr = { SVDorDVDorTVD: svdordvdortvd} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(SyntaxScore!=="")
     {
      const sr = { SyntaxScore: SyntaxScore} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(IVUSorOCT!=="")
     {
      const sr = { IVUSorOCT: IVUSorOCT} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(MB!=="")
     {
      const sr = { MB:MB} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(Route!=="")
     {
      const sr = { Route:Route} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(guide!=="")
     {
      const sr = {  Guide: guide} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(plaque!=="")
     {
      const sr = {Plaque:plaque}
      setDoc(r,sr,{merge:true});
      flag =1
     }
     if(MLA!=="")
     {
      const sr = {MLA:MLA}
      setDoc(r,sr,{merge:true});
      flag =1
     }
     if(MBDRD!=="")
     {
      const sr = {mbdrd:MBDRD}
      setDoc(r,sr,{merge:true});
      flag =1
     }

     if(SBDRD!=="")
     {
      const sr = {SBDRD:SBDRD}
      setDoc(r,sr,{merge:true});
      flag = 1
     }
     if(SBOstil!=="")
     {
      const sr = {SBOstil:SBOstil}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }

     if(LMDiameter!=="")
     {
      const sr = {LMDiameter:LMDiameter}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }

     if(PlaqueMDevice!=="")
     {
      const sr = {PlaqueMDevice:PlaqueMDevice}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }
     if(MBBalloon!=="")
     {
      const sr = {MBBalloon:MBBalloon}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }
     if(MBBalloonPressure!=="")
     {
      const sr = {MBBalloonPressure:MBBalloonPressure}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }

     if(SBdilatation!=="")
     {
      const sr = {SBdilatation:SBdilatation}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }

     if(StentName!=="")
     {
      const sr = {StentName:StentName}
      setDoc(r,sr,{merge:true});
      flag = 1;
     }
     if(stensize!=="")
     {
      const sr = {  StenSize: stensize} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(POTBalloonSize!=="")
     {
      const sr = {  POTBalloonSize: POTBalloonSize} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(POTBalloonPressure!=="")
     {
      const sr = {  POTBalloonPressure: POTBalloonPressure} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(MBPostDilatation!=="")
     {
      const sr = {  MBPostDilatation: MBPostDilatation} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(AngiographicSBC!=="")
     {
      const sr = {  AngiographicSBC: AngiographicSBC} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(ChestPain!=="")
     {
      const sr = {  ChestPain: ChestPain} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(ECGChanges!=="")
     {
      const sr = {  ECGChanges: ECGChanges} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(timiflow!=="")
     {
      const sr = {  TIMIFlow: timiflow} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(SBFFR!=="")
     {
      const sr = {  SBFFR: SBFFR} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(KBI!=="")
     {
      const sr = {  KBI: KBI} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(MBBalloonSizeForKBI!=="")
     {
      const sr = {  MBBalloonSizeForKBI: MBBalloonSizeForKBI} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(SBBalloonSizeForKBI!=="")
     {
      const sr = {  SBBalloonSizeForKBI: SBBalloonSizeForKBI} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(SBComplicationAfterKBI!=="")
     {
      const sr = {  SBComplicationAfterKBI: SBComplicationAfterKBI} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(MBMSA!=="")
     {
      const sr = {  MBMSA: MBMSA} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(LMMSA!=="")
     {
      const sr = {  LMMSA: LMMSA} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(dLM!=="")
     {
      const sr = {  dLM: dLM} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(ostialLAD!=="")
     {
      const sr = {  ostialLAD: ostialLAD} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(MBexpansion!=="")
     {
      const sr = {  MBexpansion: MBexpansion} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(LMexpansion!=="")
     {
      const sr = {  LMexpansion: LMexpansion} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(edgedissection!=="")
     {
      const sr = {  EdgeDissection: edgedissection} 
      setDoc(r,sr,{merge : true});
      flag=1
     }  
     if(WellApposed!=="")
     {
      const sr = {  WellApposed: WellApposed} 
      setDoc(r,sr,{merge : true});
      flag=1
     }  
     if(PlaqueProlapse!=="")
     {
      const sr = {  PlaqueProlapse: PlaqueProlapse} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(NStentStruts!=="")
     {
      const sr = {  NStentStruts: NStentStruts} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     
     if(LinkType!=="")
     {
      const sr = {  LinkType: LinkType} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(RNStentStruts!=="")
     {
      const sr = {  RNStentStruts: RNStentStruts} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(SBODissection!=="")
     {
      const sr = {  SBODissection: SBODissection} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
    //  if(FinalSBO!=="")
    //  {
    //   const sr = {  FinalSBO: FinalSBO} 
    //   setDoc(r,sr,{merge : true});
    //   flag=1
    //  } 
     if(DocumentCaudalViews!=="")
     {
      const sr = {  DocumentCaudalViews: DocumentCaudalViews} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(CAGSB!=="")
     {
      const sr = {  CAGSB: CAGSB} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(CAGMB!=="")
     {
      const sr = {  CAGMB: CAGMB} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(IVUSorOCTafter!=="")
     {
      const sr = {  IVUSorOCTafter: IVUSorOCTafter} 
      setDoc(r,sr,{merge : true});
      flag=1
     } 
     if(Epithelialization!=="")
     {
      const sr = {  Epithelialization: Epithelialization} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(cardiacDeath!=="")
     {
      const sr = {  cardiacDeath: cardiacDeath} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(Myocardial!=="")
     {
      const sr = {  Myocardial: Myocardial} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(TargetLesion!=="")
     {
      const sr = {  TargetLesion: TargetLesion} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(EventDate!=="")
     {
      const sr = {  EventDate: EventDate} 
      setDoc(r,sr,{merge : true});
      flag=1
     }

     if(StentThrombosis!=="")
     {
      const sr = {  StentThrombosis: StentThrombosis} 
      setDoc(r,sr,{merge : true});
      flag=1
     }
     if(flag===1)
     {
      let abc = Date();
      let xyz = abc.indexOf("GMT");
      let lmn = abc.substring(0,xyz);
      const sr = { ModifiedDate: lmn, ModifiedBy: auth.currentUser.email} 
      setDoc(r,sr,{merge : true});
      
     }
     navigate('/redirect1/');
     
  }
    return (
          <div id="patientinfo-page">
            <div id="form">
              <div id="login">
                <div id="login-header">
                  <h3>Patient Information</h3>
                  <p>Please Edit Patient Information</p>
                </div>
                <button onClick={onEdit}><b>Edit</b></button><br/>
              </div>
              <form id="patientinfo-form">
                <input name="ID" value={ID} onChange={(e) =>{setID(e.target.value)}} readOnly/>
                <input type = "text" name="name" value={name} id="id1" placeholder="Name" onChange={(e)=>{setname(e.target.value)}}/>
                <input type="text" name="operatorname" value={OperatorName} placeholder="Operator Name" onChange={(e) =>{setOperatorName(e.target.value)}}/>
                Date of Procedure:
                <input type="date" value={date} name="date"  size="30" onChange={(e) =>{setDate(e.target.value)}}/>
                <input type="number" name="age" value={age} id="id1" placeholder="Age" onChange={(e) =>{setAge(e.target.value)}}/>
               
                Sex: <br/>
                <select name="Sex" value={Sex} onChange={(e) =>{setSex(e.target.value)}}>
                  <option defaultValue={""}></option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                </select>

                
              {/*<input type="text" name="DM" value={DM} placeholder="DM" onChange={(e) =>{setDM(e.target.value)}}/>*/}
                <input type="text" name="HTN" value={HTN} placeholder="HTN (Y/N)" onChange={(e) =>{setHTN(e.target.value)}}/>
                <input type="text" name="Dyslipidemia" value={Dyslipidemia} placeholder="Dyslipidemia (Y/N)" onChange={(e) =>{setDyslipidemia(e.target.value)}}/>
                
                <input type="text" name="smoking" value={smoking} placeholder="Smoking (Y/N)" onChange={(e) =>{setSmoking(e.target.value)}}/>
                <input type="text" name="CKD" value={CKD} placeholder="CKD (Y/N)" onChange={(e) =>{setCKD(e.target.value)}}/>
                <input type="text" name="PAD" value={PAD} placeholder="PAD (Y/N)" onChange={(e) => {setPAD(e.target.value)}}/>
                <input type="text" name="PCI" value={PCI} placeholder="Prior PCI (Y/N)" onChange={(e) => {setPCI(e.target.value)}}/>
                <input type="text" name="CABG" value={CABG} placeholder="Prior CABG (Y/N)" onChange={(e) =>{setCABG(e.target.value)}}/>
                <input type="text" name="comorbidity" value={comorbidity} placeholder="Any other Co-Morbidity" onChange={(e) =>{setCormorbidty(e.target.value)}}/>
                <input type="text" name="Diagnosis" value={diagnosis} placeholder="Diagnosis" onChange={(e)=>{setDiagnosis(e.target.value)}}/>

                <input type="text" name="CSAorACS" value={CSAorACS} placeholder="CSA / ACS" onChange={(e) =>{setCSAorACS(e.target.value)}}/>
                <input type="text" name="TACS" value={TACS} placeholder="Type of ACS (STEMI / NSTEMI / USA)" onChange={(e)=>{setTACS(e.target.value)}}/>

                <input type="text" name="EF" value={EF} placeholder="EF" onChange={(e) =>{setEF(e.target.value)}}/>
               {/* <input type="text" name="operatorname" value={OperatorName} placeholder="Operator Name" onChange={(e) =>{setOperatorName(e.target.value)}}/>*/}
                 
                <br/>
               
                <input type="text" name="lm" value={lm}  placeholder="LM (% stenosis)" onChange={(e) =>{setLm(e.target.value)}}/>
                <input type="text" name="lad" value={lad} placeholder="LAD (% stenosis)" onChange={(e) =>{setLad(e.target.value)}}/>
                <input type="text" name="lcx" value={lcx} placeholder="LCx (% stenosis)" onChange={(e) =>{setLcx(e.target.value)}}/>
                <input type="text" name="rca" value={rca} placeholder="RCA (% stenosis)" onChange={(e) =>{setRca(e.target.value)}}/>
                <input type="text" name="medina" value={medina} placeholder="Medina Class" onChange={(e) =>{setmedina(e.target.value)}}/>

                <input type="text" name="svdordvdortvd" value={svdordvdortvd} placeholder="SVD / DVD / TVD" onChange={(e) =>{setSvd(e.target.value)}}/>
                <input type="text" name="syntaxscore" value={SyntaxScore} placeholder="Syntax Score" onChange={(e) =>{setSyntaxScore(e.target.value)}}/>
                <input type="text" name="ivusoroct" value={IVUSorOCT} placeholder="IVUS / OCT" onChange={(e) =>{steIVUSorOCT(e.target.value)}}/>
                <input type="text" name="mb" value={MB} placeholder="MB (LAD / LCx)" onChange={(e) =>{setMB(e.target.value)}}/>
                <input type="text" name="route" value={Route} placeholder="Route (Radial / Femoral)" onChange={(e) =>{setRoute(e.target.value)}}/>
                <input type="text" name="guide" value={guide} placeholder="Guide" onChange={(e) =>{setGuide(e.target.value)}}/>
                <input type="text" name="plaque" value={plaque} placeholder="Plaque Morphology" onChange={(e) =>{setplaque(e.target.value)}}/>
                <input type="text" name="mla" value={MLA} placeholder="MLA" onChange={(e) =>{setMLA(e.target.value)}}/>
                <input type="text" name="mbdrd" value={MBDRD} placeholder="MB-DRD" onChange={(e) =>{setMBDRD(e.target.value)}}/>
                <input type="text" name="sbdrd" value={SBDRD} placeholder="SB-DRD at 15mm from ostium" onChange={(e) =>{setSBDRD(e.target.value)}}/>
                <input type="text" name="sbostil" value={SBOstil} placeholder="SB ostial diameter" onChange={(e) =>{setSBOstil(e.target.value)}}/>
                <input type="text" name="lmdiameter" value={LMDiameter} placeholder="LM Diameter" onChange={(e) =>{setLMDiameter(e.target.value)}}/>
                <input type="text" name="plaquemdevice" value={PlaqueMDevice} placeholder="Plaque Modification device" onChange={(e) =>{setPlaqueMDevice(e.target.value)}}/>
                <input type="text" name="mbballoonsize" value={MBBalloon} placeholder="MB Balloon Size" onChange={(e) =>{setMBBalloon(e.target.value)}}/>
                <input type="text" name="mbballoonpressure" value={MBBalloonPressure} placeholder="MB Balloon Pressure" onChange={(e) =>{setMBBalloonPressure(e.target.value)}}/>
                <input type="text" name="sbdilatation" value={SBdilatation} placeholder="SB dilatation (Y/N)" onChange={(e) =>{setSBdilatation(e.target.value)}}/>
                <input type="text" name="stentname" value={StentName} placeholder="Stent Name (Optional)" onChange={(e) =>{setStentName(e.target.value)}}/>
                <input type="text" name="stensize" value={stensize} placeholder="Stent Size" onChange={(e) =>{setStsize(e.target.value)}}/>
                <input type="text" name="potballoonsize" value={POTBalloonSize} placeholder="POT Balloon Size" onChange={(e) =>{setPOTBalloonSize(e.target.value)}}/>
                <input type="text" name="potballoonpressure" value={POTBalloonPressure} placeholder="POT Balloon Pressure" onChange={(e) =>{setPOTBalloonPressure(e.target.value)}}/>
                <input type="text" name="mbpostdilatation" value={MBPostDilatation} placeholder="MB Post-dilatation (Y/N)" onChange={(e) =>{setMBPostDilatation(e.target.value)}}/>
                <input type="text" name="angiographicsbcompromise" value={AngiographicSBC} placeholder="Angiographic SB compromise (Y/N)" onChange={(e) =>{setAngiographicSBC(e.target.value)}}/>
                <input type="text" name="chestpain" value={ChestPain} placeholder="Chest Pain (Y/N)" onChange={(e) =>{setChestPain(e.target.value)}}/>
                <input type="text" name="ECGchanges" value={ECGChanges} placeholder="ECG Changes (Y/N)" onChange={(e) =>{setECGChanges(e.target.value)}}/>
                <input type="text" name="timiflowinsb" value={timiflow} placeholder="TIMI III Flow in SB (Y/N)" onChange={(e) =>{setTFlow(e.target.value)}}/>
                <input type="text" name="sbffr" value={SBFFR} placeholder="SB FFR, write NA if not done" onChange={(e) =>{setSBFFR(e.target.value)}}/>
                <input type="text" name="kbi" value={KBI} placeholder="KBI (Y/N)" onChange={(e) =>{setKBI(e.target.value)}}/>
                <input type="text" name="mbballonsizeforkbi" value={MBBalloonSizeForKBI} placeholder="MB Balloon Size for KBI" onChange={(e) =>{setMBBalloonSizeForKBI(e.target.value)}}/>
                <input type="text" name="sbballonsizeforkbi" value={SBBalloonSizeForKBI} placeholder="SB Balloon Size for KBI" onChange={(e) =>{setSBBalloonSizeForKBI(e.target.value)}}/>
                <input type="text" name="sbcomplicationafterkbi" value={SBComplicationAfterKBI} placeholder="SB Complication after KBI (Y/N)" onChange={(e) =>{setSBComplicationAfterKBI(e.target.value)}}/>
                <input type="text" name="mbmsa" value={MBMSA} placeholder="MB MSA distal to carina" onChange={(e) =>{setMBMSA(e.target.value)}}/>
                <input type="text" name="lmmsa" value={LMMSA} placeholder="LM MSA proximal to carina" onChange={(e) =>{setLMMSA(e.target.value)}}/>
                <input type="text" name="dlm" value={dLM} placeholder="dLM area" onChange={(e) =>{setdLM(e.target.value)}}/>
                <input type="text" name="ostiallad" value={ostialLAD} placeholder="Ostial LAD (MB) area" onChange={(e) =>{setostialLAD(e.target.value)}}/>
                <input type="text" name="mbexpansion" value={MBexpansion} placeholder="MB expansion distal to carina" onChange={(e) =>{setMBexpansion(e.target.value)}}/>
                <input type="text" name="LMexpansion" value={LMexpansion} placeholder="LM expansion proximal to carina" onChange={(e) =>{setLMexpansion(e.target.value)}}/>
                <input type="text" name="edgedissection" value={edgedissection} placeholder="Edge Dissection (Y/N)" onChange={(e) =>{setEdissection(e.target.value)}}/>
                <input type="text" name="wellapposed" value={WellApposed} placeholder="Well apposed (Y/N)" onChange={(e) =>{setWellApposed(e.target.value)}}/>
                <input type="text" name="plaqueprolapse" value={PlaqueProlapse} placeholder="Plaque Prolapse (Y/N)" onChange={(e) =>{setPlaqueProlapse(e.target.value)}}/>
                <input type="text" name="nstentstruts" value={NStentStruts} placeholder="Number of stent strunts across SBO in non-KBI groups" onChange={(e) =>{setNStentStruts(e.target.value)}}/>
                <input type="text" name="linktype" value={LinkType} placeholder="Link type / Link free carina in non-KBI group" onChange={(e) =>{setLinkType(e.target.value)}}/>
                <input type="number" name="rnstentstruts" value={RNStentStruts} placeholder="Remaining Number of stent strunts in KBI groups" onChange={(e) =>{setRNStentStruts(e.target.value)}}/>
                <input type="text" name="sbodissection" value={SBODissection} placeholder="SBO dissection / thrombus in KBI group (Y/N)" onChange={(e) =>{setSBODissection(e.target.value)}}/>
                <input type="text" name="documentcaudalviews" value={DocumentCaudalViews} placeholder="Document Caudal views on which SBO is left for comparison after one year" onChange={(e) =>{setDocumentCaudalViews(e.target.value)}}/>
                <input type="text" name="CAGSB" value={CAGSB} placeholder="CAG after 1 year SB Restenosis on same caudal view" onChange={(e) =>{setCAGSB(e.target.value)}}/>
                <input type="text" name="CAGMB" value={CAGMB} placeholder="CAG after 1 year MB Restenosis" onChange={(e) =>{setCAGMB(e.target.value)}}/>
                <input type="text" name="ivusoroctafter" value={IVUSorOCTafter} placeholder="IVUS / OCT after one year (Y/N)" onChange={(e) =>{setIVUSorOCTafter(e.target.value)}}/>
                <input type="text" name="epithelialization" value={Epithelialization} placeholder="Epithelization of hanging stent struts (Y/N)" onChange={(e) =>{setEpithelialization(e.target.value)}}/>
                <input type="text" name="cardicdeath" value={cardiacDeath} placeholder="Cardiac Death (CD) (Y/N)" onChange={(e) =>{setcardiacDeath(e.target.value)}}/>
                <input type="text" name="myocardial" value={Myocardial} placeholder="Myocardial Infarction (MI) (Y/N)" onChange={(e) =>{setMyocardial(e.target.value)}}/>
                <input type="text" name="targetlesion" value={TargetLesion} placeholder="Target Lesion Revascularization (TLR) (Y/N)" onChange={(e) =>{setTargetLesion(e.target.value)}}/>
                Event Date (Event=CD, MI or TLR):
                <input type="date" value={EventDate} name="eventdate" size="30" onChange={(e) =>{setEventDate(e.target.value)}}/>
                <input type="text" name="stentthrombosis" value={StentThrombosis} placeholder="Stent Thrombosis (Y/N)" onChange={(e) =>{setStentThrombosis(e.target.value)}}/>

              </form>
              <button onClick={onEdit}><b>Edit</b></button>
            </div>
          </div>  
    )
}
export default Patient_Info