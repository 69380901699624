import React, { useEffect, useRef, useState } from 'react';
import { auth } from './fbgen2';
import "./random.css";
import { useNavigate, useParams } from 'react-router-dom';
import { collection, doc, getDocs, setDoc } from 'firebase/firestore';
import { obj,type1,type2,type3,type4,type5,type6,type7,type8,type9,type10,type11,type12,type13,type14,type16,type17,type18,type19,type20,type21,type22,type23,type24,type25,type26,type27,type28,type29,type30,type31,type32 } from './fbgen2';
import Load from "./Load.json";
import FrameLoad from "./FrameLoad.json";
import Lottie from 'lottie-react';



let limit = 630;
let db_limit = 200;
let diff = 0;
let db_diff = 0;
var tk;
var tnk;
var db_tk;
var db_tnk;

function Random() {
    const entryButtonRef = useRef(null);
    let rand = 0;
    const { id } = useParams();
    const [num, setNum] = useState('');
    const [loading, setLoading] = useState(false);
    const [showButton, setShowButton] = useState(true); //button visibility trigger
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('No');

    const selectOption = (option) => {
        setSelectedOption(option);
        console.log(selectedOption)
    };

    const randomize = async (e) => {
        e.preventDefault();
        let min = 1, max = 3;
        let difference = max - min;
        rand = Math.random();
        rand = Math.floor(rand * difference);
        rand = rand + min;
        let data = '';
        console.log("Test",auth.currentUser.email)
        if (auth.currentUser.email === type1) {
            data = "CET02";
        } else if (auth.currentUser.email === type2) {
            data = "CET03";
        } else if (auth.currentUser.email === type3) {
            data = "CET04";
        } else if (auth.currentUser.email === type4) {
            data = "CET05";
        } else if (auth.currentUser.email === type5) {
            data = "CET06";
        } else if (auth.currentUser.email === type6) {
            data = "CET07";
        } else if (auth.currentUser.email === type7) {
            data = "CET08";
        } else if (auth.currentUser.email === type8) {
            data = "CET09";
        } else if (auth.currentUser.email === type9) {
            data = "CET10";
        } else if (auth.currentUser.email === type10) {
            data = "CET11";
        } else if (auth.currentUser.email === type11) {
            data = "CET12";
        } else if (auth.currentUser.email === type12) {
            data = "CET13";
        } else if (auth.currentUser.email === type13) {
            data = "CET14";
        } else if (auth.currentUser.email === type14) {
            data = "CET15";
        } else if (auth.currentUser.email === type16) {
            data = "CET16"
        } else if (auth.currentUser.email === type17) {
            data = "CET17" 
        } else if (auth.currentUser.email === type18) {
            data = "CET18" 
        } else if (auth.currentUser.email === type19) {
            data = "CET19" 
        } else if (auth.currentUser.email === type20) {
            data = "CET20" 
        } else if (auth.currentUser.email === type21) {
            data = "CET21" 
        } else if (auth.currentUser.email === type22) {
            data = "CET22" 
        } else if (auth.currentUser.email === type23) {
            data = "CET23" 
        } else if (auth.currentUser.email === type24) {
            data = "CET24" 
        } else if (auth.currentUser.email === type25) {
            data = "CET25" 
        } else if (auth.currentUser.email === type26) {
            data = "CET26" 
        } else if (auth.currentUser.email === type27) {
            data = "CET27" 
        } else if (auth.currentUser.email === type28) {
            data = "CET28" 
        } else if (auth.currentUser.email === type29) {
            data = "CET29" 
        } else if (auth.currentUser.email === type30) {
            data = "CET30" 
        } else if (auth.currentUser.email === type31) {
            data = "CET31" 
        } else if (auth.currentUser.email === type32) {
            data = "CET32" 
        }
        else {
            data = "CET01";
        }

        let ent1 = [];
        let ent2 = [];
        const r = doc(obj, data, id);

        if(selectedOption == "Yes")
        {
            const docref1 = collection(obj,"DB_TK");
            const docref2 = collection(obj,"DB_TNK");
            const docref3 = doc(obj,"DB_TK","DB_TKV");
            const docref4 = doc(obj,"DB_TNK","DB_TNKV");

            await getDocs(docref1) // await is used so that when a value is retrieved from such functions is doesnt get updated as a promise state is yet to be fulfilled.
            .then((snapshot) =>{
                snapshot.docs.forEach((doc)=>{
                    ent1.push({ ...doc.data(), id: doc.id})
            })
            db_tk = ent1[0].VALUE   
            })
        
            await getDocs(docref2) // await is used so that when a value is retrieved from such functions is doesnt get updated as a promise state is yet to be fulfilled.
            .then((snapshot) =>{
                snapshot.docs.forEach((doc)=>{
                    ent2.push({ ...doc.data(), id: doc.id})
            })
            db_tnk = ent2[0].VALUE   
            })
            setLoading(true);
            setShowButton(false); // Hide the button after displaying num
            db_diff = db_tk - db_tnk;
            if (rand === 1) {
/* IF DIFFERENCE IS GREATER THAN 5% OF LIMIT THEN NO RANDOMIZATION WILL OCCUR IN THAT CASE (1:1 RATIO)*/
            var op;
            if (db_diff<0)
            {
                db_diff = db_diff * -1;
            }
            if((db_diff > (0.05 * db_limit) && db_tk > db_tnk) || db_tk == 0.5*db_limit)
            {
                op = "Non-KBI" //TO NOT K.I.S.S
                const setDB_TNK = {
                VALUE: db_tnk + 1
                };
            await setDoc(docref4,setDB_TNK);
            }
            else
            {
                op = "KBI" //TO K.I.S.S
                const setDB_TK = {
                    VALUE: db_tk + 1
                    };
                await setDoc(docref3,setDB_TK);
            }
            const setData = {
                Operation: op,
                DM: selectedOption
            };
            await setDoc(r, setData, { merge: true });
            setNum(op);
        } 
        else
        {
            if (db_diff<0)
            {
                db_diff = db_diff * -1;
            }
            if((db_diff > (0.05 * db_limit) && db_tnk > db_tk) || db_tnk == 0.5*db_limit)
            {
                op = "KBI" //TO K.I.S.S
                const setDB_TK = {
                    VALUE: db_tk + 1
                    };
                await setDoc(docref3,setDB_TK);
            }
            else
            {
                op = "Non-KBI" //TO NOT K.I.S.S
                const setDB_TNK = {
                    VALUE: db_tnk + 1
                };
                await setDoc(docref4,setDB_TNK);
            }
            const setData = {
                Operation: op,
                DM: selectedOption
            };
            getDocs(docref2)
            .then((snapshot) =>{
                snapshot.docs.forEach((doc)=>{
                    ent2.push({ ...doc.data(), id: doc.id})
            })
        })
        await setDoc(r, setData, { merge: true });
        setNum(op);
        }
        }
        else if(selectedOption == "No")
        {
            const docref1 = collection(obj,"TK");
            const docref2 = collection(obj,"TNK");
            const docref3 = doc(obj,"TK","TKV");
            const docref4 = doc(obj,"TNK","TNKV");
    
            await getDocs(docref1) // await is used so that when a value is retrieved from such functions is doesnt get updated as a promise state is yet to be fulfilled.
            .then((snapshot) =>{
                snapshot.docs.forEach((doc)=>{
                    ent1.push({ ...doc.data(), id: doc.id})
            })
            tk = ent1[0].VALUE   
        })
            
            await getDocs(docref2) // await is used so that when a value is retrieved from such functions is doesnt get updated as a promise state is yet to be fulfilled.
            .then((snapshot) =>{
                snapshot.docs.forEach((doc)=>{
                    ent2.push({ ...doc.data(), id: doc.id})
            })
            tnk = ent2[0].VALUE   
        })
            setLoading(true);
            setShowButton(false); // Hide the button after displaying num
            diff = tk - tnk;
            if (rand === 1) {
    /* IF DIFFERENCE IS GREATER THAN 5% OF LIMIT THEN NO RANDOMIZATION WILL OCCUR IN THAT CASE (1:1 RATIO)*/
                //console.log(diff)
                var op;
                if (diff<0)
                {
                    diff = diff * -1;
                }
                if((diff > (0.05 * limit) && tk > tnk) || tk == 0.5*limit)
                {
                    console.log("Predicted : TK , Overwritten : TNK " + "Diff = " , diff)
                    op = "Non-KBI" //TO NOT K.I.S.S
                    const setTNK = {
                    VALUE: tnk + 1
                    };
                await setDoc(docref4,setTNK);
                }
                else
                {
                    console.log("Predicted : TK" + "Diff = " , diff)
                    op = "KBI" //TO K.I.S.S
                    const setTK = {
                        VALUE: tk + 1
                        };
                    await setDoc(docref3,setTK);
                }
                const setData = {
                    Operation: op,
                    DM: selectedOption
                };
                await setDoc(r, setData, { merge: true });
                setNum(op);
            } else {
                if (diff<0)
                {
                    diff = diff * -1;
                }
                if((diff > (0.05 * limit) && tnk > tk) || tnk == 0.5*limit)
                {
                    console.log("Predicted : TNK , Overwritten : TK " + "Diff = " , diff)
                    op = "KBI" //TO K.I.S.S
                    const setTK = {
                        VALUE: tk + 1
                        };
                    await setDoc(docref3,setTK);
                }
                else
                {
                    console.log("Predicted : TNK " + "Diff = " , diff)
                    op = "Non-KBI" //TO NOT K.I.S.S
                    const setTNK = {
                        VALUE: tnk + 1
                    };
                    await setDoc(docref4,setTNK);
                }
                const setData = {
                    Operation: op,
                    DM: selectedOption
                };
                /*getDocs(docref2)
                .then((snapshot) =>{
                    snapshot.docs.forEach((doc)=>{
                        ent2.push({ ...doc.data(), id: doc.id})
                })
                let key1 = 0; //IF DECLARED OUTSIDE WILL GET REFRESHED. HENCE USE IT INSIDE WHERE U NEED TO UPDATE.
                key1 = ent2[0].VALUE;
                console.log(key1);
                key1 = key1 + 1;
                const setPR = {
                    VALUE: key1
                };
                setDoc(docref4,setPR);
            })*/
    
                await setDoc(r, setData, { merge: true });
                setNum(op);
            }
        }
       

        setTimeout(() => {
            setLoading(false);
           
            setTimeout(() => {
                setNum('');
                navigate('/redirect3/');
            }, 5000);
        }, 4000);
    };

    useEffect(() => {
        let timer;
        if (num) {
            timer = setTimeout(() => {
                setNum('');
            }, 7000);
        }
        return () => clearTimeout(timer);
    }, [num]);

    return (
        <div id="random-div1">
            <div id="random-page">
                <div id="random-form1">
                    <div id="random-header">
                        <h2 style={{ color: "Black" }}>Is Patient Diabetic?</h2>
                    </div>
                    <div id="random-oput">
                        
                    </div>
                    
                    <div id="random-form2">
                    <button id="yes-btn" onClick={() => selectOption('Yes')} disabled={selectedOption === 'Yes'} className={selectedOption === 'Yes' ? 'selected' : ''}>
                        <b><font size="3.5">Yes</font></b>
                        </button>
                        <button id="no-btn" onClick={() => selectOption('No')} disabled={selectedOption === 'No'} className={selectedOption === 'No' ? 'selected' : ''}>
                        <b><font size="3.5">No</font></b>
                        </button>
                    </div>
                </div>

                <div id="random-form1">
                    <div id="random-header">
                        <h2 style={{ color: "Black" }}>IKISS RANDOMIZER</h2>
                    </div>
                    <div id="random-oput">
                        {loading ? (
                            <div className="loading-sign">
                                <div style={{width: "55%" , marginLeft : "28%"}}>
                                <Lottie loop={true}  animationData={Load}/> 
                                </div>
                                  
                                <div style={{width: "55%" , marginLeft : "28%"}}>
                                <Lottie loop={true} animationData={FrameLoad}/>    
                                </div>
                                
                            </div>
                        ) : (
                            <input id="random-nonedit" value={num} readOnly />
                        )}
                    </div>
                    <div id="random-form2">
                        {showButton && (
                            <button id="randomize-btn" onClick={randomize}>
                                <b><font size="3.5">Randomize!</font></b>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>              
    );
}

export default Random;