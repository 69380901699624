import React, { useState } from 'react';
import "./Acc.css";
import Navbar from './NavBar';
import { initializeApp } from "firebase/app";
import { collection, DocumentSnapshot, getDocs, getFirestore,onSnapshot } from "firebase/firestore";
import { doc, setDoc,updateDoc,deleteDoc,getDoc } from "firebase/firestore";
import { auth,obj,type1,type2,type3,type4,type5,type6,type7,type8,type9,type10,type11,type12,type13,type14,type15,type16,type17,type18,type19,type20,type21,type22,type23,type24,type25,type26,type27,type28,type29,type30,type31 } from "./fbgen2" 


function Acc() {
  const [selectedOption, setSelectedOption] = useState('Select');

  const [data, setData] = useState([]);
  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  var colref = collection(obj,"SNO")
          getDocs(colref)
          .then((snapshot) =>{ 
              snapshot.docs.forEach((doc)=>{
                  data.push({ ...doc.data(),id: doc.id})
              })
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage)
        });

        const findValueById = (ID) => {
          const item = data.find(item => item.id === ID);
          return item ? item.VALUE : 'Not found';
        };

        const findPercentage = (Value) => {
          return ((Value/700)*100).toFixed(2);
        };

  return (
    <div>
      <Navbar />
      <div>
        <center>
          <br></br>
          <h2 className="acc">Account Details</h2><br/><hr></hr>
        </center>
      </div>
      <div id='menwindow'>
        <center>
          <label htmlFor="optn">Choose :</label>
          <select name="optn" id="optn" value={selectedOption} onChange={handleChange}>
            <option value="Select" id='optn0'>Select</option>
            <option value="AHRR" id='optn2'>AHR&R</option>
            <option value="AICTS" id='optn3'>AICTS</option>
            <option value="CHAFB" id='optn4'>CHAFB</option>
            <option value="JH" id='optn5'>JH</option>
            <option value="JLNA" id='optn6'>JLNA</option>
            <option value="KIMS" id='optn7'>KIMS</option>
            <option value="LFHRC" id='optn8'>LFHRC</option>
            <option value="AG" id='optn1'>MICC</option>
            <option value="MSC" id='optn9'>MMM</option>
            <option value="PGI" id='optn10'>PGI Chandigarh</option>
            <option value="RH" id='optn11'>RH</option>
            <option value="SGMM" id='optn12'>SGMM</option>
            <option value="SGPGI" id='optn13'>SGPGI</option>
            <option value="SGRRIMHS" id='optn14'>SGRRIMHS</option>
            <option value="YHS" id='optn15'>YHS</option>
            <option value="GRH" id='optn16'>GRH</option>
            <option value="MHCCH" id='optn17'>MHCCH</option>
            <option value="MD" id='optn18'>MD</option>
            <option value="IH" id='optn19'>IH</option>
            <option value="SRMK" id='optn21'>SRMK</option>
            <option value="SSH" id='optn22'>SSH</option>
            <option value="UH" id='optn23'>UH</option>
            <option value="SH" id='optn24'>SH</option>
            <option value="CHMH" id='optn25'>CH-MH</option>
            <option value="CET24" id='optn26'>CET24</option>
            <option value="CET25" id='optn27'>CET25</option>
            <option value="CET26" id='optn28'>CET26</option>
            <option value="CET27" id='optn29'>CET27</option>
            <option value="CET28" id='optn30'>CET28</option>
            <option value="CET29" id='optn31'>CET29</option>
            <option value="CET30" id='optn32'>CET30</option>

            <option value="ALL" id='optn33'>ALL</option>
          </select>

          {selectedOption === 'Select' && (
            <div className="container">
             
            </div>
          )}
          
          {selectedOption === 'AG' && (
            <div className="container">
              <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
              <div className="grid-container">
                <div className="hospital-name">Metromed International Cardiac Center</div>
                <div className="trials-available">Number of Entries</div>
                <div className="trials-available-value">{findValueById("AG")}</div>
                <div className="patients-name">Percentage of Entry</div>
                <div className="patients-name-value">{findPercentage(findValueById("AG"))}%</div>
              </div>
            </div>
          )}

          {selectedOption === 'AHRR' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Army Hospital Research and Referral</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("AHRR")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("AHRR"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'AICTS' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Army Institute of Cardio-Thoracic Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("AICTS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("AICTS"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CHAFB' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Command Hospital Air Force Bangalore</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CHAFB")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CHAFB"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'JH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Jehangir Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("JH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("JH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'JLNA' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Jawaharlal Nehru Medical College</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("JLNA")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("JLNA"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'KIMS' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Krishna Institute of Medical Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("KIMS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("KIMS"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'LFHRC' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Little Flower Hospital and Research Centre</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("LFHRC")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("LFHRC"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'MSC' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Meenakshi Mission Hospital and Research centre</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("MSC")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("MSC"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'PGI' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Post Graduate Institute of Medical Education and Research</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("PGI")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("PGI"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'RH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Rajasthan Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("RH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("RH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'SGMM' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Saint Gregorios Medical Mission Multi-Speciality Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SGMM")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SGMM"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'SGPGI' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Sanjay Gandhi Post Graduate Institute of Medical Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SGPGI")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SGPGI"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'SGRRIMHS' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Shree Guru Ram Rai Institute of Medical & Health Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SGRRIMHS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SGRRIMHS"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'YHS' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Yashoda Hospital Secunderabad</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("YHS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("YHS"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'MHCCH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Manoria Heart and Critical Care Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("MHCCH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("MHCCH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'MD' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Max Dehradun</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("MD")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("MD"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'IH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Indus Hospital Vyzac</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("IH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("IH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'SRMK' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">SRM Kikabhai Mumbai</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SRMK")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SRMK"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'SSH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Symbiosis Speciality Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SSH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SSH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'UH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Unicare Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("UH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("UH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'GRH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Ganga Ram Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("GRH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("GRH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'SH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Star Hospital Ahmedabad</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CHMH' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Caritas Hospital Multispeciality Hospital Kottayam</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CHMH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CHMH"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET24' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 24</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET24")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET24"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET25' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 25</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET25")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET25"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET26' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 26</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET26")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET26"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET27' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 27</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET27")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET27"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET28' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 28</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET28")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET28"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET29' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 29</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET29")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET29"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'CET30' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 30</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET30")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET30"))}%</div>
                </div>
              </div>
            </div>
          )}

          {selectedOption === 'ALL' && (
            <div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Army Hospital Research and Referral</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("AHRR")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("AHRR"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Army Institute of Cardio-Thoracic Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("AICTS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("AICTS"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Command Hospital Air Force Bangalore</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CHAFB")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CHAFB"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Ganga Ram Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("YHS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("YHS"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Indus Hospital Vyzac</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("IH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("IH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Jehangir Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("JH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("JH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Jawaharlal Nehru Medical College</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("JLNA")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("JLNA"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Krishna Institute of Medical Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("KIMS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("KIMS"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Little Flower Hospital and Research Centre</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("LFHRC")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("LFHRC"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Manoria Heart and Critical Care Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("MHCCH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("MHCCH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Max Dehradun</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("MD")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("MD"))}%</div>
                </div>
              </div>
              <div className="container">
              <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
              <div className="grid-container">
                <div className="hospital-name">Metromed International Cardiac Center</div>
                <div className="trials-available">Number of Entries</div>
                <div className="trials-available-value">{findValueById("AG")}</div>
                <div className="patients-name">Percentage of Entry</div>
                <div className="patients-name-value">{findPercentage(findValueById("AG"))}%</div>
              </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Meenakshi Mission Hospital and Research centre</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("MSC")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("MSC"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Post Graduate Institute of Medical Education and Research</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("PGI")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("PGI"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Rajasthan Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("RH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("RH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Saint Gregorios Medical Mission Multi-Speciality Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SGMM")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SGMM"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Sanjay Gandhi Post Graduate Institute of Medical Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SGPGI")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SGPGI"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Shree Guru Ram Rai Institute of Medical & Health Sciences</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SGRRIMHS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SGRRIMHS"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">SRM Kikabhai Mumbai</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SRMK")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SRMK"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Symbiosis Speciality Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SSH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SSH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Unicare Hospital</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("UH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("UH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Yashoda Hospital Secunderabad</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("YHS")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("YHS"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Star Hospital Ahmedabad</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("SH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("SH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Caritas Hospital Multispeciality Hospital Kottayam</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CHMH")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CHMH"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 24</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET24")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET24"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 25</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET25")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET25"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 26</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET26")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET26"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 27</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET27")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET27"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 28</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET28")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET28"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 29</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET29")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET29"))}%</div>
                </div>
              </div>
              <div className="container">
                <div className="image" style={{ backgroundImage: "url('https://nfpc.in/wp-content/uploads/2021/06/ch3.jpg')" }}></div>
                <div className="grid-container">
                  <div className="hospital-name">Centre 30</div>
                  <div className="trials-available">Number of Entries</div>
                  <div className="trials-available-value">{findValueById("CET30")}</div>
                  <div className="patients-name">Percentage of Entry</div>
                  <div className="patients-name-value">{findPercentage(findValueById("CET30"))}%</div>
                </div>
              </div>
            </div>
          )}    
        </center>
      </div>
    </div>
  );
}

export default Acc;