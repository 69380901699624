import {  sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword   } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import { Switch , Route, Routes, useNavigate, BrowserRouter,useInRouterContext, useRoutes, Navigate } from 'react-router-dom';
//import Operations from './patientdetails.js';
//import { auth } from './fbgen';
//import Operations1 from './patientdetails.js';
//import PatientTable from './PatientTable.js';
//import Operations from './patient_info.js';
//import Patientdetails from './patientdetails.js'
//import "./PatientTable.css"
import Loginn from './Loginn';
import Procedure from './Procedure';
import Operations1 from './patientdetails';
import PatientTable from './PatientTable';
import PatientTable3 from './PatientTable3';
import Patient_Entry from './patient_entry';
import PT2 from './pt2';
import Random from './random';
import Patient_Info from './patient_info';
import Acc from './Acc';
import RefreshPage from './RefreshPage';
import RefreshUpdate from './RefreshUpdate';
import RefreshRandom from './RefreshRandom';
import LoadAfterRandom from './LoadAfterRandom'

const App =() => 
{


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useInRouterContext();
  
const gotorandom = () =>
  {
    navigate('/LOLO');
  }
  
  /*const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("Successfully loged");
        //navigate("/home")
        useNavigate("/");
        
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
    });

    
   
}

const onForgot = (e) => {
  e.preventDefault();
  sendPasswordResetEmail(auth, email)
  .then((userCredential) => {
      //Add your code here
      console.log("Sent successfully.")
  })
  .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
  });

  
 
}
*/

  return (
    /*<form method='post'>
          <input type = "email" name="Email-ID" onChange={(e)=>setEmail(e.target.value)}>
          </input>
          <input type = "password" name="Password" onChange={(e)=>setPassword(e.target.value)}>
          </input>
          <input type="submit" value= "submit" onClick={onForgot}>
          </input>
        </form>*/
        <div>
          <BrowserRouter>
          <Routes>
            <Route exact path ='/' element={<Loginn/>}>
            </Route>
            <Route path = '/LOLO/:id/:db' element={<Patient_Info/>}>
            </Route>
            <Route path = '/LOLO/' element={<Patient_Info/>}>
            </Route>
            <Route path = '/abc/:id' element={<PatientTable/>}>
            </Route>
            <Route path = '/abc/' element={<PatientTable/>}>
            </Route>
            <Route path = '/patient_ent/:id' element={<Patient_Entry/>}>
            </Route>
            <Route path = '/patient_ent/' element={<Patient_Entry/>}>
            </Route>
            <Route path = '/acc/' element={<Acc/>}>
            </Route>
            <Route path = '/redirect/' element={<RefreshPage/>}>
            </Route>
            <Route path = '/redirect1/' element={<RefreshUpdate/>}>
            </Route>
            <Route path = '/redirect2/' element={<RefreshRandom/>}>
            </Route>
            <Route path = '/redirect3/' element={<LoadAfterRandom/>}>
            </Route>
            <Route path = '/random/:id' element={<Random/>}>
            </Route>
            <Route path = '/procedure/' element={<Procedure/>}>
            </Route>
          </Routes>
          </BrowserRouter>
        </div>
  );
}

export default App;
