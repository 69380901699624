import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig1 = {
  apiKey: "AIzaSyAK5ki7EWzT6ZRLshsbDvuofLmDBZyIWL8",
  authDomain: "ikimtrial.firebaseapp.com",
  projectId: "ikimtrial",
  storageBucket: "ikimtrial.appspot.com",
  messagingSenderId: "803232401118",
  appId: "1:803232401118:web:d378a472489a1dd176244f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig1);
const auth = getAuth();
const obj = getFirestore(app);
const type1 = "ratheeshkj76@gmail.com"
const type2 = "navjyotkhela@gmail.com"
const type3 = "rajeshvijay999@gmail.com"
const type4 = "drroopalik@gmail.com"
const type5 = "drajitmehta@gmail.com"
const type6 = "drajayswamy@gmail.com"
const type7 = "drnkmahesh@gmail.com"
const type8 = "maniselvacardio@gmail.com"
const type9 = "tanujclear@gmail.com"
const type10 = "rsrao.sn@gmail.com"
const type11 = "dr.daulatsinghmeena@gmail.com"
const type12 = "sajannarayanan@gmail.com"
const type13 = "aditya.ms11@gmail.com"
const type14 = "arungopi@gmail.com"
const type15 = "drankushgupta@gmail.com"
const type16 = "arunmohanty25@gmail.com"
const type17 = "pankaj.manoria@gmail.com"
const type18 = "preetisharma76.ps@gmail.com"
const type19 = "vipperlasujatha@gmail.com"
const type20 = "cvpatil27468@gmail.com"
const type21 = "parikhcjp@gmail.com"
const type22 = "drankurmd@gmail.com"
const type23 = "drashishtrivs@gmail.com"
const type24 = "ikisslm.centre24@gmail.com"
const type25 = "ikisslm.centre25@gmail.com"
const type26 = "ikisslm.centre26@gmail.com"
const type27 = "ikisslm.centre27@gmail.com"
const type28 = "ikisslm.centre28@gmail.com"
const type29 = "ikisslm.centre29@gmail.com"
const type30 = "ikisslm.centre30@gmail.com"
const type31 = "drtarunmadancardio75@gmail.com"
const type32 = "drdeepakdavidson@gmail.com"

export {obj,auth,
  type1,type2,type3,type4,type5,
  type6,type7,type8,type9,type10,
  type11,type12,type13,type14,type15,
  type16,type17,type18,type19,type20,
  type21,type22,type23,type24,type25,
  type26,type27,type28,type29,type30,
  type31,type32};
