import React,{ useState, useEffect } from "react";
import "./Loginn.css";
import logo1 from "./images/bp-s.png";
import Marquee from "react-fast-marquee";
import { auth } from './fbgen2'
import { useNavigate, NavLink, Navigate } from "react-router-dom";
import { sendPasswordResetEmail, signInWithEmailAndPassword,setPersistence,browserSessionPersistence   } from 'firebase/auth'

const Loginn = () => {
    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  
  const navigate = useNavigate()
  let type = 'Type2'
  const onLogin = (e) => {
    
    e.preventDefault();
    
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
        // Signed in
        navigate('/acc');
        setPersistence(auth,browserSessionPersistence) .then(() => {
            // Existing and future Auth states are now persisted in the current
            // session only. Closing the window would clear any existing state even
            // if a user forgets to sign out.
            // ...
            // New sign-in will be persisted with session persistence.
            //console.log("Session Started");
          })
          .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
          });
        const user = auth.currentUser;
        //console.log("Successfully loged" + user.email);

    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log(errorCode, errorMessage)
        alert("WRONG USERNAME/PASSWORD")
    });
}

const onForgot = (e) => {
    
  e.preventDefault();
  sendPasswordResetEmail(auth, email)
  .then((userCredential) => {
      //Add your code here
      //console.log("Sent successfully.")
      alert(`Reset link is send to  ${email}`);
  })
  .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
  });

}

const click = () => {
navigate('/random');
}
    return(            
            <div id="login-div1">
                
                <div id="login-logo1">
                    {/*<img src={logo1} alt="logo"/>*/}
                    <b><font size="6">IKiss Left Main Trial</font></b>
                </div>

            
                <div id="login-marq">
                    <Marquee direction="left" speed={100} gradient style={{marginLeft:'50px'}}><b><font size="4" color="black">Impact of KISSing balloon inflation on provisional Left Main bifurcation percutaneous coronary intervention: A prospective multicenter randomized controlled trial (IKISS LM trial)
                    </font></b>
                    {/*<img src={logo1} alt="logo" width={50} height={50}/>*/}
                    </Marquee>
                </div>

                <div id="login-page">
                    
                        <div id="login-form1">
                            <div id="login-header">
                                    <h2 style={{color: "White"}}>Get Started</h2>
                            </div>

                            <div id="login-form2">
                                    <form id="login-form">
                                        <input type="text" placeholder="UserName" onChange={(e)=>setEmail(e.target.value)} id="lg1"/>
                                        <input type="password" placeholder="Password" onChange={(e)=>setPassword(e.target.value)} id="lg2"/>
                                        <a href="#" onClick={onForgot}><font style={{color: "White"}}>Forgot password?</font></a>
                                    </form>
                            </div>
                        </div>

                        <button id="login-btn" onClick={onLogin}><b><font size="4">Login</font></b></button>
                        
                </div>      
            </div>
    )
}

export default Loginn