import { useRef, useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

function Navbar() {
	const navRef = useRef();

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

	

	
	
	

	return (
		<header>
			<h3>IKiss LM Trial</h3>
			<nav ref={navRef}>
				<ul className="no-bullet">
					<li>
					<NavLink   to="/acc/" activeclassname="active" className="nav-link">Account</NavLink>
					</li>
				</ul>
				
				<ul className="no-bullet">
					<li>
					<NavLink  to="/patient_ent/"  activeclassname="active" className="nav-link">Randomizer</NavLink>
					</li>
				</ul>

				<ul className="no-bullet">
					<li>
					<NavLink to="/abc/" activeclassname="active" className="nav-link">List</NavLink>
					</li>
				</ul>

				<ul className="no-bullet">
					<li>
					<NavLink to="/procedure/" activeclassname="active" className="nav-link">Procedure</NavLink>
					</li>
				</ul>
				
				
				
				
				
				
				
				
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default Navbar;